<template>
  <el-card class="form-container" shadow="never">
    <el-form
      :model="productCate"
      :rules="rules"
      ref="productCateForm"
      label-width="150px"
    >
      <el-form-item label="Category Name：" prop="name">
        <el-input v-model="productCate.name"></el-input>
      </el-form-item>
      <el-form-item label="分类名称_CN：" prop="name_ch">
        <el-input v-model="productCate.name_ch"></el-input>
      </el-form-item>
      <el-form-item label="Sku Section：" prop="skuSection">
        <el-input v-model="productCate.skuSection"></el-input>
      </el-form-item>
      <el-form-item label="上级分类：">
        <el-select v-model="productCate.parentId" placeholder="请选择分类">
          <el-option
            v-for="item in selectProductCateList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Category类型：">
        <el-select v-model="productCate.categoryType" placeholder="请选择分类">
          <el-option
            v-for="item in categoryTypeOptions"
            :key="item"
            :label="item"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否显示：">
        <el-radio-group v-model="productCate.visible">
          <el-radio :label="true">是</el-radio>
          <el-radio :label="false">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="Thumbnail">
        <single-upload v-model="productCate.thumbnail" auto-complete="off"></single-upload>
      </el-form-item>
      <el-form-item label="Bg Img">
        <single-upload v-model="productCate.bgImage" auto-complete="off"></single-upload>
      </el-form-item>
      <el-form-item label="Primary Picture">
        <single-upload v-model="primaryPic" auto-complete="off"></single-upload>
      </el-form-item>
      <el-form-item label="Addtional Pictures">
        <multi-upload
          v-model="productCate.imageUrls"
          :isUploading.sync="isUploading"
          :inputList="inputPics"
        ></multi-upload>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit('productCateForm')"
          >提交</el-button
        >
        <el-button v-if="!isEdit" @click="resetForm('productCateForm')"
          >重置</el-button
        >
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
import {
  fetchOptions,
  createProductCate,
  updateProductCate,
  getProductCate,
} from '@/api/productCate';
import MultiUpload from '@/components/Upload/multiUpload';
import SingleUpload from '@/components/Upload/singleUpload';

const defaultProductCate = {
  name: '',
  name_ch: '',
  sortOrder: 0,
  depth: 0,
  visible: true,
  parentId: null,
  skuSection: null,
  categoryType: null,
  thumbnail: null,
  imageUrls: [],
};
export default {
  name: 'ProductCateDetail',
  components: { MultiUpload, SingleUpload },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      productCate: { ...defaultProductCate },
      primaryPic: null,
      inputPics: [],
      isUploading: false,
      selectProductCateList: [],
      categoryTypeOptions: ['PHYSICAL', 'DIGITAL'],
      rules: {
        name: [
          { required: true, message: '请输入目录名称', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur',
          },
        ],
        name_ch: [
          { required: true, message: '请输入目录名称', trigger: 'blur' },
          {
            min: 2,
            max: 140,
            message: '长度在 2 到 140 个字符',
            trigger: 'blur',
          },
        ],
        skuSection: {
          required: true,
          min: 2,
          max: 2,
          message: 'Please input sku section with 2 characters',
          trigger: 'blur',
        },
      },
    };
  },
  created() {
    if (this.isEdit) {
      getProductCate(this.$route.query.id).then((response) => {
        this.productCate = response.data;
        this.primaryPic = response.data.imageUrls.shift();
        response.data.imageUrls.forEach((url) => { this.inputPics.push({ url }); });
      });
    } else {
      this.productCate = { ...defaultProductCate };
    }
    this.getSelectProductCateList();
  },
  methods: {
    getSelectProductCateList() {
      fetchOptions().then((response) => {
        this.selectProductCateList = response.data;
        this.selectProductCateList.forEach((x) => { x.depth = 0; });
        this.selectProductCateList.unshift({
          id: null,
          name: '无上级分类',
          depth: -1,
        });
      });
    },
    onSubmit(formName) {
      if (this.isUploading) {
        this.$message({
          message: 'Please wait until upload complete',
          type: 'error',
          duration: 1000,
        });
        return false;
      }
      if (this.primaryPic != null) {
        this.productCate.imageUrls.unshift(this.primaryPic);
      }
      for (let i = 0; i < this.selectProductCateList.length; i += 1) {
        const pc = this.selectProductCateList[i];
        if (pc.id == this.productCate.parentId) {
          this.productCate.depth = pc.depth + 1;
          break;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$confirm('是否提交数据', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }).then(() => {
            if (this.isEdit) {
              updateProductCate(this.$route.query.id, this.productCate).then(
                (response) => {
                  this.$message({
                    message: '修改成功',
                    type: 'success',
                    duration: 1000,
                  });
                  this.$router.back();
                },
              );
            } else {
              createProductCate(this.productCate).then((response) => {
                this.$refs[formName].resetFields();
                this.resetForm(formName);
                this.$message({
                  message: '提交成功',
                  type: 'success',
                  duration: 1000,
                });
              });
            }
          });
        } else {
          this.$message({
            message: '验证失败',
            type: 'error',
            duration: 1000,
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.productCate = { ...defaultProductCate };
      this.getSelectProductCateList();
    },
  },
};
</script>

<style scoped>
</style>
